import { cn } from '@lemonenergy/lemonpie-components'
import { Slot } from '@radix-ui/react-slot'
import { forwardRef } from 'react'

interface TextButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  inverted?: boolean
  asChild?: boolean
}

const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
  ({ children, inverted, className, asChild, ...props }, ref) => {
    const Component = asChild ? Slot : 'button'
    const colorStyle = inverted ? `fg-inverted-main` : `fg-expressive-strong`

    const buttonStyle = `
    lemonpie-text-button
    bg-transparent
    cursor-pointer
    border-none
    py-nudge px-smallest
    user-select-none
    m-0
    inline-flex
    items-center
    justify-center
    w-fit
    rounded-smallest
    no-underline
    typography-action-small

    ${colorStyle}

    active:bg-soft
  `

    return (
      <Component className={cn(buttonStyle, className)} ref={ref} {...props}>
        {children}
      </Component>
    )
  },
)

TextButton.displayName = 'TextButton'

export default TextButton
